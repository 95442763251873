import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Login from '../views/LoginView.vue'
import CaseScene from '../views/CaseScene.vue'
import TaiwanMap from '../views/TaiwanMap.vue'
import ChartView from '../views/ChartView.vue'
import ReportView from '../views/ReportView.vue'

import Admin from '../views/admin/AdminView.vue'
import AdminCompany from '../views/admin/CompanyView.vue'
import AdminUser from '../views/admin/UserView.vue'
import AdminCaseScene from '../views/admin/CaseScene.vue'
import AdminDetection from '../views/admin/DetectionView.vue'
import AdminThermalDefect from '../views/admin/ThermalDefect.vue'
import AdminPointsHistory from '../views/admin/PointsHistory.vue'
import AdminIdentity from '../views/admin/IdentityView.vue'
import AdminCapacity from '../views/admin/CapacityView.vue'
import AdminWeather from '../views/admin/WeatherView.vue'
import AdminClean from '../views/admin/CleanView.vue'
import AdminThermalCameraPixel from '../views/admin/ThermalCameraPixel.vue'
import AdminThermalDefectCategory from '../views/admin/ThermalDefectCategory.vue'

Vue.use(VueRouter)
Vue.use(VueAxios, axios)

const routes = [
  {
    path: '/login',
    name: '登入',
    component: Login
  },
  {
    path: '/case-scene',
    name: '案場',
    component: CaseScene
  },
  {
    path: '/taiwan-map',
    name: '台灣地圖',
    component: TaiwanMap
  },
  {
    path: '/chart',
    name: '圖表',
    component: ChartView
  },
  {
    path: '/report',
    name: '報告',
    component: ReportView
  },
  {
    path: '/admin',
    name: '管理者',
    component: Admin,
    children: [
      {
        path: 'company',
        name: '公司',
        component: AdminCompany
      },
      {
        path: 'user',
        name: '使用者',
        component: AdminUser
      },
      {
        path: 'case-scene',
        name: '公司',
        component: AdminCaseScene
      },
      {
        path: 'detection',
        name: '檢測',
        component: AdminDetection
      },
      {
        path: 'thermal-defect',
        name: '熱缺陷',
        component: AdminThermalDefect
      },
      {
        path: 'points-history',
        name: '點數歷程',
        component: AdminPointsHistory
      },
      {
        path: 'identity',
        name: '身份',
        component: AdminIdentity
      },
      {
        path: 'capacity',
        name: '裝置容量',
        component: AdminCapacity
      },
      {
        path: 'weather',
        name: '天氣狀況',
        component: AdminWeather
      },
      {
        path: 'clean',
        name: '清潔狀況',
        component: AdminClean
      },
      {
        path: 'thermal-camera-pixel',
        name: '熱像儀像素',
        component: AdminThermalCameraPixel
      },
      {
        path: 'thermal-defect-category',
        name: '熱缺陷種類',
        component: AdminThermalDefectCategory
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
