<template>
  <div>
    <b-form-row>
      <b-col cols="3">
        <b-form-group
          label="公司"
        >
          <b-form-select
            v-model="form.company_id"
            :options="resources.companies"
            value-field="id"
            text-field="name"
            @change="changeCompany"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-button
          variant="outline-primary"
          @click="showModal('新增')"
          style="margin-top: 31px;"
        >新增</b-button>
      </b-col>
    </b-form-row>
    
    <b-table
      :items="resources.pointsHistory"
      :fields="fields"
      striped
      hover
      small
      responsive
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(action)="data">
        {{ data.item.action == '1' ? '儲值' : '檢測申請' }}
      </template>
      <template #cell(created_at)="data">
        {{ new Date(data.item.created_at).format('Y-m-d H:i') }}
      </template>
      <template #cell(updated_at)="data">
        {{ data.item.updated_at ? new Date(data.item.updated_at).format('Y-m-d H:i') : '' }}
      </template>
      <template #cell(functions)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          class="mr-2"
          @click="showModal('編輯', data.item)"
          >編輯</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          @click="deletePointsHistory(data.item)"
          >刪除</b-button
        >
      </template>
    </b-table>

    <b-modal
      size="lg"
      ref="modalRef"
      :title="modalTitle"
      ok-title="儲存"
      cancel-title="取消"
      :no-close-on-backdrop="true"
      :no-close-on-esc="false"      
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-row>
          <b-col cols="4">
            <b-form-group
              label="動作"
              invalid-feedback="必填"
              :state="state.action"
              class="fm-required"
            >
              <b-form-select
                  v-model="form.action"
                  :options="resources.pointsActions"
                  value-field="value"
                  text-field="text"
                  required
                  @input="checkState('action')"
                >
                  <template #first>
                    <b-form-select-option :value="null">-- 請選擇 --</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="點數"
              invalid-feedback="必填"
              :state="state.points"
              class="fm-required"
            >
              <b-form-input
                v-model="form.points"
                type="number"
                required
                @input="checkState('points')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="檢測 ID"
            >
              <b-form-select
                v-model="form.detection_id"
                :options="resources.detections"
                value-field="id"
                text-field="id"
              >
                <template #first>
                  <b-form-select-option :value="null">-- 請選擇 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="起始日期"
              invalid-feedback="必填"
              :state="state.begin_date"
              class="fm-required"
            >
              <datepicker
                v-model="form.begin_date"
                :value="form.begin_date"
                :language="zh"
                :bootstrap-styling="true"
                format="yyyy-MM-dd"
                required
                @input="checkState('begin_date')"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="截止日期"
              invalid-feedback="必填"
              :state="state.end_date"
              class="fm-required"
            >
              <datepicker
                v-model="form.end_date"
                :value="form.end_date"
                :language="zh"
                :bootstrap-styling="true"
                format="yyyy-MM-dd"
                required
                @input="checkState('end_date')"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="文字輸入"
              :state="state.note"
            >
              <b-form-textarea
                v-model="form.note"
                rows="3"
                autocomplete="off"
                @input="checkState('note')"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { zh } from 'vuejs-datepicker/dist/locale'   // 設定語系

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      zh: zh,
      modalTitle: null,
      pointsHistoryID: null,
      state: {
        action: null,
        points: null,
        begin_date: null,
        end_date: null,
        note: null
      },
      form: {
        action: null,
        points: null,
        detection_id: null,
        begin_date: null,
        end_date: null,
        note: null,
      },
      resources: {
        companies: [],
        caseScenes: [],
        detections: [],
        pointsHistory: [],
        pointsActions: []
      },
      fields: [
        { key: 'index', label: '#' },
        { key: 'action', label: '動作' },
        { key: 'points', label: '點數' },
        { key: 'detection_id', label: '檢測 ID', sortable: true },
        { key: 'begin_date', label: '起始日期', sortable: true },
        { key: 'end_date', label: '截止日期', sortable: true },
        { key: 'note', label: '文字輸入' },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true },
        { key: 'functions', label: '功能' },
      ],
    }
  },
  methods: {
    deletePointsHistory(item) {
      if (confirm("確定刪除！")) {
        this.axios
          .delete(`/points-history/${item.id}`)
          .then(() => {
            const index = this.resources.pointsHistory.findIndex(
              (element) => element.id == item.id
            );
            this.resources.pointsHistory.splice(index, 1);

            this.makeToast("系統訊息", "success", "刪除成功！");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkState (name) {
      this.state[name] = this.form[name] ? true : false;
    },
    showModal (type, row) {
      this.$refs.modalRef.show();

      if (type == '新增') {
        this.modalTitle = type;
      // 編輯
      } else {
        this.modalTitle = `${type} - ID ${row.id}`;
        this.pointsHistoryID = row.id;

        for (let prop in this.form) {
          if (prop != 'company_id') {
            this.form[prop] = row[prop];
          }
        }
      }
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity();
 
      // Explicitly check each date field
      const dateFields = ['begin_date', 'end_date'];
      let datesValid = true;

      dateFields.forEach(field => {
        if (!this.form[field]) {
          this.state[field] = false;
          datesValid = false;
        } else {
          this.state[field] = true;
        }
      });

      for (let prop in this.state) {
        this.state[prop] = this.form[prop] ? true : false;
      }

      return valid && datesValid;
    },
    resetModal () {
      /**
       * 回復預設值
       */
      for (let prop in this.form) {
        if (prop != 'company_id') {
          this.form[prop] = null;
        }
      }

      for (let prop in this.state) {
        this.state[prop] = null;
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      // Process dates in `this.form`
      ['begin_date', 'end_date'].forEach(prop => {
        if (this.form[prop] && typeof this.form[prop] !== 'string') {
          this.form[prop] = this.form[prop].toISOString().split('T')[0];
        }
      });

      if (this.modalTitle == '新增') {
        this.axios
          .post('/points-history', this.form)
          .then(response => {
            this.resources.pointsHistory.push(response.data.data[0]);
            this.makeToast('系統訊息', 'success', '新增成功！');
          })
          .catch(error => {
            console.log(error);
          });
      // 編輯
      } else {
        this.axios.patch('/points-history/' + this.pointsHistoryID, this.form)
          .then(response => {
            let index = this.resources.pointsHistory.findIndex(
              element => element.id == response.data.data[0].id
            );
            this.resources.pointsHistory.splice(index, 1, response.data.data[0]);
            this.makeToast('系統訊息', 'success', '資料已更新！');
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // Push the name to submitted names
      // this.submit.push(this.name)
      // Hide the modal manually
      this.$nextTick (() => {
        this.$refs.modalRef.hide()
      });
    },
    getPointsHistory () {
      this.axios
        .get('/points-history', { params: { 
          filter: `points_history.company_id:${this.form.company_id}`
        }})
        .then(response => {
          this.resources.pointsHistory = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCaseScene () {
      this.axios
        .get('/case-scene', { params: {
          filter: `case_scene.company_id:${this.form.company_id}`
        }})
        .then(response => {
          this.resources.caseScenes = response.data.data;
          this.getDetection();
        })
        .catch(error => {
          console.log(error);
        });
    },
    getDetection () {
      // Check if caseScenes is not empty before proceeding to get detections
      if (this.resources.caseScenes.length > 0) {
        const caseSceneIds = this.resources.caseScenes.map(scene => scene.id); // Get all case scene ids

        this.axios
          .get('/detection', { params: {
            filterin: `case_scene_id:${caseSceneIds.join('-')}` // Fetch detections by multiple case scene ids
          }})
          .then(response => {
            this.resources.detections = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.resources.detections = []; // Reset detections if no case scenes
      }
    },
    changeCompany () {
      //this.resources.pointsHistory = [];  // Prevent showing old data
      this.getPointsHistory();
      this.getCaseScene();
    }
  },
  created () {
    this.axios
      .get("/company")
      .then((response) => {
        this.resources.companies = response.data.data;
        this.form.company_id = this.resources.companies[0].id;

        this.getPointsHistory();
        this.getCaseScene();
      })
      .catch((error) => {
        console.log(error);
      });

    this.axios
      .get('/points-actions')
      .then(response => {
        this.resources.pointsActions = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });

  }
}
</script>

<style scoped>
.table >>> thead th {
  position: sticky !important;
  top: 0;
  text-align: center;
  z-index: 2;
  background-color: white;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.3);
  border-bottom: 0px;
}
.table-responsive >>> .table thead th,
.table-responsive >>> .table tbody td {
  font-size: 0.9rem;
  vertical-align: middle !important;
  /* 防止資料折行 */
  word-break: keep-all;
  white-space: nowrap;
}

/*
 * 必填欄位加 *
 */
.fm-required >>> legend::after {
  color: red;
  content: " *";
}
</style>
