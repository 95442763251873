<template>
  <div>
    <b-container>
      <b-row>
        <b-col class="text-center text-md-right mb-3" cols="12" md="6">
          <b-row>
            <b-col cols="12">
              <img src="../assets/iruav-logo.png" class="img-fluid" width="260">
            </b-col>
            <b-col cols="12">
              <img src="../assets/background.jpg" class="img-fluid" style="width: 250px;">
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6">
          <div class="border rounded p-3 mx-auto mr-md-auto" style="width: 250px;">
            <b-form-group
              label="帳號"
              description="電子郵件"
              class="mb-2"
            >
              <b-form-input
                v-model="form.email"
                type="email"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>
  
            <b-form-group
              label="密碼"
              class="mb-4"
            >
              <b-form-input
                v-model="form.password"
                type="password"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>

            <div class="text-center">
              <b-button
                class="mr-4"
                type="button"
                variant="outline-success"
                @click="$bvModal.show('fm-register')"
              >註冊
              </b-button>

              <b-button
                type="button"
                variant="outline-primary"
                @click="login()"
              >登入
              </b-button>
                            
              <b-button
                @click="$bvModal.show('fm-modal')"
                variant="link"
                style="font-size: 14px;"
              >隱私權政策</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="fm-register" hide-footer>
      <template #modal-title>
        註冊
      </template>
      <div>
        <b-embed
          type="iframe"
          aspect="16by9"
          src="https://iruav.com.tw/iruavapp-register/"
          allowfullscreen
          height="710"
        ></b-embed>
      </div>
    </b-modal>

    <b-modal id="fm-modal" size="xl" hide-footer>
      <template #modal-title>
        隱私權政策
      </template>
      <div>
        <p>為了讓您能夠安心使用雲端視覺化系統的各項服務，特此向您說明本系統的隱私權政策，以保障您的權益，請詳閱下列內容：</p>

        <h3>一、隱私權保護政策的適用範圍</h3>
        <p>隱私權保護政策內容，包括本系統如何處理您在系統內的資料，隱私權保護政策不適用於本系統以外的相關連結網站，也不適用於非本系統所委託或參與管理的人員。</p>
        
        <h3>二、客戶資料的蒐集、處理及利用方式</h3>
        <p>當客戶使用本系統所提供之功能服務時，我們將視作業需要，請客戶提供必要的資料，並在該特定目的範圍內處理及利用客戶提供資料，非經客戶書面同意，本系統不會將客戶的資料提供系統內其他客戶或系統之外的用途。</p>
        
        <h3>三、資料之保護</h3>
        <p>本系統之主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護系統運作及客戶的資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。如因業務需要有必要委託其他單位提供服務時，本系統亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其確實遵守。</p>
        
        <h3>四、資料之查閱與刪除</h3>
        <p>客戶能隨時登入系統查閱資料並下載自身專案內資料。專案資料在系統儲存空間足夠下將永久為客戶保存，若儲存空間不足或資料過期時我們將在知會客戶後進行刪除。</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    login () {
      this.axios
        .post('/login', {
          email: this.form.email,
          password: this.form.password
        })
        .then(response => {
          if (response.data.status) {
            localStorage.setItem('token', response.data.token);
            this.$store.commit('setToken', response.data.token);

            this.axios
              .get('/profile')
              .then(response => {
                if (response.data.status) {
                  localStorage.setItem('user', JSON.stringify(response.data.data));
                  this.$store.commit('setUser', response.data.data);
                  
                  // Check if the user is an admin
                  const isAdmin = response.data.data.admin === 1;

                  // Redirect based on admin status
                  if (isAdmin) {
                    this.$router.push({ path: '/admin/company' });
                  } else {
                    this.$router.push({ path: '/taiwan-map' });
                  }
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.makeToast('系統訊息', 'danger', '登入失敗！');
          }
        })
        .catch(error => {
          console.log(error);
          this.makeToast('系統訊息', 'danger', '登入失敗！');
        });
    }
  }
}
</script>

<style scoped>
.embed-responsive {
  height: 701px;
}
</style>