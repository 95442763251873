import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL="https://api-platform.iruav.com.tw/api";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

Vue.prototype.makeToast = function (title, variant = null, content) {
  this.$bvToast.toast(content, {
    title: title,
    variant: variant,
    autoHideDelay: 5000,
  });
}


/**
 * 同 PHP date format (https://www.php.net/manual/zh/function.date.php
 * @param {string} format 格式
 * @returns {string}
 */
Date.prototype.format = function(format) {
  let mm = this.getMonth() + 1; // getMonth() is zero-based
  let dd = this.getDate();
  let hh = this.getHours();
  let ii = this.getMinutes();
  let ss = this.getSeconds();
  let arr = [];

  if (format == 'm-d') {
    arr = [
      (mm <= 9 ? '0' : '') + mm,
      '-',
      (dd <= 9 ? '0' : '') + dd,
    ];
  } else if (format == 'H:i:s') {
    arr = [
      (hh <= 9 ? '0' : '') + hh,
      ':',
      (ii <= 9 ? '0' : '') + ii,
      ':',
      (ss <= 9 ? '0' : '') + ss
    ];
  } else if (format == 'm-d H:i') {
    arr = [
      (mm <= 9 ? '0' : '') + mm,
      '-',
      (dd <= 9 ? '0' : '') + dd,
      ' ',
      (hh <= 9 ? '0' : '') + hh,
      ':',
      (ii <= 9 ? '0' : '') + ii,
    ];
  // Y-m-d
  } else {
    arr = [
      this.getFullYear(),
      '-',
      (mm <= 9 ? '0' : '') + mm,
      '-',
      (dd <= 9 ? '0' : '') + dd,
    ];
  }
  
  switch (format) {
    case 'Y-m-d H:i':
      arr.push(
        ' ',
        (hh <= 9 ? '0' : '') + hh,
        ':',
        (ii <= 9 ? '0' : '') + ii,
      )
      break;
    case 'Y-m-d H:i:s':
      arr.push(
        ' ',
        (hh <= 9 ? '0' : '') + hh,
        ':',
        (ii <= 9 ? '0' : '') + ii,
        ':',
        (ss <= 9 ? '0' : '') + ss
      )
      break;
    case 'Y-m-d His':
      arr.push(
        ' ',
        (hh <= 9 ? '0' : '') + hh,
        (ii <= 9 ? '0' : '') + ii,
        (ss <= 9 ? '0' : '') + ss
      )
      break;
  }
  
  return arr.join('');
};

/**
 * 請求攔截器
 */
// https://stackoverflow.com/questions/43051291/attach-authorization-header-for-all-axios-requests#answer-43052288
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${store.state.token}`;
  return config;
});


// 回應攔截器
var logout = 0;
axios.interceptors.response.use(function (response) {
  logout = 0;
  // HTTP 2xx 狀態碼觸發
  return response;
// 超出 2xx 狀態碼觸發
}, function (error) {
  logout++;

  if (error.response.status === 401 && logout === 1) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    
    alert('使用者已登出此帳號，請重新登入！');

    router.push({ path: '/login' }).catch(()=>{});
  } else {
    console.log(error);
  }
});

router.beforeEach((to, from, next) => {
  // 如果 localStorage 已有存放
  if (localStorage.getItem('token')) {
    // 將 localStorage 資料儲存一份至 vuex
    store.commit('setToken', localStorage.getItem('token'));
    store.commit('setUser', JSON.parse(localStorage.getItem('user')));    
    
    if (to.path == '/login' || to.path == '/') {
      // 依權限進入指定頁面路徑 (須需與 Login.vue 相同)
      if (store.state.user.admin === 1) {
        next({ path: '/admin/user' });
      } else {
        next({ path: '/taiwan-map' });
      }
    } else {
      next();
    }
  } else {
    // 如果要進入的網頁是 login 直接進入，如果不是則進入 login
    if (to.path == '/login') {
      next();
    } else {
      next({ path: '/login' })
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
