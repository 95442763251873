<template>
  <div id="chart-content">
    <div ref="loadingScreen" :class="{ 'loading-screen': showLoadingScreen, 'loading-screen fade-out': !showLoadingScreen }">
      <div v-if="showLoadingScreen" class="loading-icon"></div>
    </div>
    <div ref="echart" style="height:100%;"></div>
    <div style="position:absolute; top:60px; left:20px;">
      <b-button ref="download" id="download" v-b-tooltip.hover title="下載圖片">
        <b-icon 
          icon="download"
          @click="downloadImage"
        ></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      showLoadingScreen: true,
      caseSceneId: null,
      detectionIds: [],
      resources: {
        caseScenes: [],
        detections: [],
        thermalDefects: [],
        defectCategories: []
      },
      // ECharts options
      option: {
        title: {
          text: null,
          left: "center",
          textStyle: {
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          top: '50px',
          textStyle: {
            fontSize: 16,
          },
        },
        grid: {
          top: '100px',
          left: "0%",
          right: "4%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, "5%"],
          minInterval: 1,
          axisLabel: {
            fontSize: 15,
            fontWeight: 'bold',
            color: '#ddd',
          },
          splitLine: {
            show: true,
            lineStyle: {
                color: [
                  "#666"
                ]
            }
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            fontSize: 15,
            fontWeight: 'bold',
            color: '#ddd',
          },
          data: [],
        },
        series: [],
      },
    };
  },
  mounted() {
    this.getDefectCategories();
  },
  methods: {
    /**
     * Get defect categories
     */  
    getDefectCategories(){
      this.axios.get('/thermal-defect-category')
      .then(response => {
        this.resources.defectCategories = response.data.data;
        this.getCaseScene();
      })
      .catch(error => {
        console.log(error);
      });
    },

    /**
     * Get case scene
     */  
    getCaseScene() {
      this.caseSceneId = parseInt(localStorage.getItem('CaseSceneId'), 10);
      this.axios.get('/case-scene', { params: {
        filter : `case_scene.id:${this.caseSceneId}`
      }})
      .then(response => {
        this.resources.caseScenes = response.data.data;
        this.getDetections();
      })
      .catch(error => {
        console.log(error);
      });
    },

    /**
     * Get detections
     */  
    getDetections() {
      this.axios.get('/detection', { params: {
        filter : `case_scene_id:${this.caseSceneId}`
      }})
      .then(response => {
        this.resources.detections = response.data.data;
        // Extracting all detection IDs and storing them in thisDetectionIds
        this.resources.detections.forEach(detection => {
          this.detectionIds.push(detection.id);
        });
        this.getThermalDefect();
      })
      .catch(error => {
        console.log(error);
      });
    },

    /**
     * Get thermal defects
     */  
    getThermalDefect () {
      // Create an array to store all axios requests
      let axiosRequests = [];

      // Call getThermalDefect for each detection ID
      this.detectionIds.forEach(detectionId => {
        // Push each axios request into the axiosRequests array
        axiosRequests.push(
          this.axios.get('/thermal-defect', { params: { 
            filter: `thermal_defect.detection_id:${detectionId}` 
          }})
        );
      });

      // Use Promise.all to wait for all axios requests to complete
      Promise.all(axiosRequests)
        .then(responses => {
          // Iterate over each response
          responses.forEach(response => {
            this.resources.thermalDefects.push(...response.data.data); // Spread operator to push each object
          });
          
          // Call initChart after all axios requests have completed
          this.initChart();
        })
        .catch(error => {
          console.log(error);
        });
    },

    /**
     * Build the chart
     */  
    initChart() {
      let categoriesData = {
        yAxisData: [],
        categoriesId: [],
      };
      let series = [];

      this.resources.defectCategories.forEach(defectCategory => {
        categoriesData.yAxisData.push(defectCategory.name);
        categoriesData.categoriesId.push(defectCategory.id);
      });

      this.resources.detections.forEach(detection => {
        let serie = {  // Create a new serie object for each detection
          name: detection.detection_date,
          type: "bar",
          data: []
        };
        categoriesData.categoriesId.forEach(catId => {
          serie.data.push(this.getTotalDefects(detection.id, catId)); 
        });
        series.push(serie);  // Push the newly created serie object into the series array
      });

      this.option.title.text =  "熱缺陷種類及片數\n" + this.resources.caseScenes[0].name + " " + this.resources.caseScenes[0].device_capacity + this.resources.caseScenes[0].capacity_unit;
      this.option.yAxis.data = categoriesData.yAxisData;
      this.option.series = series;

      let chart = echarts.init(this.$refs.echart, 'dark');
      chart.setOption(this.option);

      // Show download button
      const downloadButton = this.$refs.download;
      if (downloadButton) {
        downloadButton.style.opacity = 1;
      }

      // Remove loading screen 
      if (this.showLoadingScreen) { 
        this.removeLoadingScreen();
      }
    },

    /**
     * Calculate the total defects for a detection id and a defect category
     */  
    getTotalDefects(detectionId,categoryId) {
      let total = 0;
      
      // Loop through thermalDefects and count occurrences of categoryId
      for (let i = 0; i < this.resources.thermalDefects.length; i++) {
        // Check if detection_id matches detectionId and thermal_defect_category_id matches categoryId
        if (this.resources.thermalDefects[i].detection_id === detectionId && this.resources.thermalDefects[i].thermal_defect_category_id === categoryId) {
          total++;
        }
      }

      // Return the total count
      return total;
    },

    /**
     * Download the chart as image
     */  
    downloadImage() {
      // Use the ref attribute to directly access the chart container element
      const chartContainer = this.$refs.echart;
      // Select the chart
      const chart = echarts.getInstanceByDom(chartContainer);
  
      // Convert the chart to base64 data URL
      const dataURL = chart.getDataURL({
        type: 'png', // Specify the image type
        pixelRatio: 2, // Adjust pixel ratio for better quality
      });

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = '熱缺陷種類及片數 - ' + this.resources.caseScenes[0].name + ' - 統計圖.png'; // Set the default file name
      link.click(); // Trigger the download
    },

    /**
     * Remove loading screen
     */         
     removeLoadingScreen() {
      this.showLoadingScreen = false;
      setTimeout(() => {
        const loadingScreen = this.$refs.loadingScreen;
        if (loadingScreen) {
          loadingScreen.remove();
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
#chart-content {
  height:100%;
  padding:20px;
}
#download {
  background:transparent;
  border: 2px solid #aaa;
  padding: 5px 10px 8px 10px;
  opacity:0;
  transition: opacity 1s; 
}
.loading-screen {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: #100c2a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 20000;
  transition: opacity .5s; 
  opacity: 1;
}
.loading-screen.fade-out {
  opacity: 0;
}
.loading-icon {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite; /* Spin animation */
  margin: auto auto; /* Center the icon */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>